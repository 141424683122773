import { useEffect, useRef } from 'react';

export default function useDismissible(onRequestDismissal) {
  const ref = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onRequestDismissal();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onRequestDismissal, ref]);
  return { ref };
}
