import { createContext, useContext, useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

// Configure Firebase.
const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
let firebaseApp;
if (!firebaseApp) firebase.initializeApp(config);

export const getAPIToken = () => firebase.auth().currentUser?.getIdToken();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(); // Local signed-in state.

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(async (user) => {
      setIsAuthenticated(!!user);
    });
    return () => unregisterAuthObserver(); // Unregister Firebase observers when the component unmounts.
  }, [setIsAuthenticated]);

  const signOut = () => firebase.auth().signOut();

  const value = { isAuthenticated, setIsAuthenticated, signOut, getAPIToken };
  return (
    <AuthContext.Provider value={value}>
      {typeof isAuthenticated === 'undefined' ? null : children}
    </AuthContext.Provider>
  );
};

export const AuthContext = createContext();

export function useAuthContext() {
  return useContext(AuthContext);
}

export { firebase };
