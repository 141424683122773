import { useLayoutEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { formatCurrency } from 'utils/format';
import styles from './Tooltip.module.scss';

export default function Tooltip({ tooltip }) {
  const ref = useRef();
  const [position, setPosition] = useState({ top: 0, left: 0 });
  useLayoutEffect(() => {
    if (!tooltip || !ref.current) return;
    const { boundingBox } = tooltip;
    const horizontalMargins = 16;
    setPosition({
      top: boundingBox.y - ref.current.clientHeight - 20,
      left: Math.max(
        horizontalMargins,
        Math.min(
          window.innerWidth - ref.current.clientWidth - horizontalMargins,
          boundingBox.x - ref.current.clientWidth / 2
        )
      ),
    });
  }, [tooltip]);
  if (!tooltip) return null;

  const { data, index, labels } = tooltip;

  const swatches = [
    ['#34AC68', '#91D9B0'],
    ['#797979', '#C5C4C4'],
  ];

  return ReactDOM.createPortal(
    <div className={styles.tooltip} style={position} ref={ref}>
      {[...data].reverse().map((group, g) => (
        <div className={styles.group} key={g}>
          <div className={styles.header}>{labels[g]}</div>
          <div className={styles.body}>
            <div className={styles.legendItem}>
              <div className={styles.swatch} style={{ background: swatches[g][0] }} />
              <div>
                <div>Net Sales</div>
                <div className={styles.value}>{formatCurrency(group[index].subtotal)}</div>
              </div>
            </div>
            <div className={styles.legendItem}>
              <div className={styles.swatch} style={{ background: swatches[g][1] }} />
              <div>
                <div>Tips</div>
                <div className={styles.value}>{formatCurrency(group[index].tips)}</div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>,
    document.getElementById('root')
  );
}
