import styles from './Filters.module.scss';
import { useCustomersContext } from './Context';
import RestaurantSelection from 'components/filters/RestaurantSelection';
import { useUserContext } from 'contexts/user';

export default function Filters() {
  const { restaurants: userRestaurants } = useUserContext();
  const { restaurants: selectedRestaurants, setRestaurants } = useCustomersContext();

  return (
    <div className={styles.filters}>
      <div className={styles.row}>
        <div className={styles.filter}>
          <RestaurantSelection
            restaurants={userRestaurants}
            selection={selectedRestaurants}
            onChange={setRestaurants}
          />
        </div>
      </div>
    </div>
  );
}
