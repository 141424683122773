import { useState } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import useDismissible from 'utils/useDismissible';
import styles from './CustomDateRange.module.scss';
import selectStyles from 'components/core/forms/Select.module.scss';
import { DateTime } from 'luxon';
import { useMediaQuery } from 'react-responsive';
import { BottomDrawer } from 'components/core/modals';
import { Button } from 'components/core/buttons';
import breakpoints from 'theme/breakpoints.module.scss';
import './styles.scss';

export default function CustomDateRange({ value, onChange }) {
  const [visible, setVisible] = useState();
  const [range, setRange] = useState({ from: value?.start.toJSDate(), to: value?.end.toJSDate() });
  const isDesktop = useMediaQuery({ query: `(min-width: ${breakpoints.mobile})` });
  const { ref } = useDismissible(() => isDesktop && setVisible(false));
  const handleDayClick = (day) => {
    const updated = DateUtils.addDayToRange(day, range);
    if (updated.from || updated.to) {
      if (updated.from && updated.to) {
        onChange({
          start: DateTime.fromJSDate(updated.from),
          end: DateTime.fromJSDate(updated.to),
        });
      } else {
        onChange({
          start: DateTime.fromJSDate(updated.from ?? updated.to),
          end: DateTime.fromJSDate(updated.from ?? updated.to),
        });
      }
    }
    setRange(updated);
  };
  const DayPickerComponent = (
    <DayPicker
      weekdaysShort={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
      selectedDays={[range.from, range]}
      modifiers={{ start: range.from, end: range.to }}
      firstDayOfWeek={1}
      onDayClick={handleDayClick}
      captionElement={({ date }) => (
        <div className="DayPicker-Caption">{DateTime.fromJSDate(date).toFormat('MMMM')}</div>
      )}
    />
  );

  return (
    <div style={{ position: 'relative' }} ref={ref}>
      <div className={selectStyles.select} onClick={() => setVisible(true)}>
        {range.from && DateTime.fromJSDate(range.from).toLocaleString(DateTime.DATE_SHORT)}
        {range.to && ' to ' + DateTime.fromJSDate(range.to).toLocaleString(DateTime.DATE_SHORT)}
        {!range.from && 'Select a date range'}
      </div>

      {!isDesktop && visible && (
        <BottomDrawer onRequestClose={() => setVisible(false)}>
          {DayPickerComponent}
          <div style={{ padding: '48px 0' }}>
            <Button onClick={() => setVisible(false)} style={{ width: '100%' }}>
              Done
            </Button>
          </div>
        </BottomDrawer>
      )}
      {isDesktop && visible && <div className={styles.dropdown}>{DayPickerComponent}</div>}
    </div>
  );
}
