import { gql } from '@urql/core';

export const overviewQuery = gql`
  query OverviewQuery(
    $restaurantIds: [ID!]!
    $startDate: ISO8601Date!
    $endDate: ISO8601Date!
    $startTime: Int
    $endTime: Int
  ) {
    stats(
      restaurantIds: $restaurantIds
      startTime: $startTime
      endTime: $endTime
      startDate: $startDate
      endDate: $endDate
    ) {
      averageSale
      averageTipPercent
      averageTip
      numOrders
      subtotal
      tax
      tips
      total
      totalDiscounts
      totalRefunds
      bonusable
      employeeBonus
      restaurantBonus
    }
  }
`;
