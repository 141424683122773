import { createContext, useContext, useMemo, useState } from 'react';
import { useUserContext } from 'contexts/user';

export const CustomersContext = createContext();

function fromLocalStorage() {
  try {
    return JSON.parse(localStorage.getItem('filters'));
  } catch (ex) {
    return null;
  }
}

export const CustomersProvider = ({ children }) => {
  const { restaurants: userRestaurants } = useUserContext();
  const [restaurants, setRestaurantsState] = useState(
    fromLocalStorage()
      ?.restaurants?.map((id) => userRestaurants.find((r) => id === r.id))
      .filter((x) => !!x) ?? userRestaurants
  );
  const setRestaurants = (value) => {
    const existing = fromLocalStorage() ?? {};
    localStorage?.setItem(
      'filters',
      JSON.stringify({ ...existing, restaurants: value.map(({ id }) => id) })
    );
    setRestaurantsState(value);
  };
  const customersQueryVariables = useMemo(
    () => ({
      restaurantIds: restaurants.map(({ id }) => id),
    }),
    [restaurants]
  );
  const value = { customersQueryVariables, restaurants, setRestaurants };
  return <CustomersContext.Provider value={value}>{children}</CustomersContext.Provider>;
};

export function useCustomersContext() {
  return useContext(CustomersContext);
}
