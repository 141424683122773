import { gql } from '@urql/core';

export const topAddonsQuery = gql`
  query TopAddonsQuery(
    $restaurantIds: [ID!]!
    $startDate: ISO8601Date!
    $endDate: ISO8601Date!
    $startTime: Int
    $endTime: Int
  ) {
    stats(
      restaurantIds: $restaurantIds
      startTime: $startTime
      endTime: $endTime
      startDate: $startDate
      endDate: $endDate
    ) {
      topAddons {
        amount
        name
        quantity
      }
    }
  }
`;
