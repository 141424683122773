import Filters from './Filters';
import Table from './Table';
import { CustomersProvider } from './Context';

export default function Customers() {
  return (
    <CustomersProvider>
      <main>
        <div className="container">
          <h1>Customers</h1>
          <Filters />
          <Table />
        </div>
      </main>
    </CustomersProvider>
  );
}
