import styles from './ChartEmpty.module.scss';
import { Loading } from 'components/icons';

export default function ChartLoading() {
  return (
    <div className={styles.empty}>
      <Loading className={styles.loading} width={32} height={32} />
      <div className={styles.title}>Loading Data</div>
    </div>
  );
}
