import styles from './FormField.module.scss';
import TextInput from './TextInput';

export default function TimeInput({ label, Component = TextInput, value, style, ...props }) {
  return (
    <label
      className={[styles.container, value && 'active'].filter((x) => x).join(' ')}
      style={style}
    >
      <Component value={value} className={styles.input} {...props} />
      <span className={styles.label}>{label}</span>
    </label>
  );
}
