import styles from './ChartEmpty.module.scss';

export default function ChartEmpty() {
  return (
    <div className={styles.empty}>
      <div className={styles.emoji}>👀</div>
      <div className={styles.title}>Nothing to See Here</div>
      <div className={styles.description}>No transactions were placed in this time frame.</div>
    </div>
  );
}
