import styles from './Switch.module.scss';
export default function Switch({ labels, on, onChange }) {
  return (
    <div onClick={() => onChange(!on)} className={styles.switch}>
      <div
        className={[styles.option, styles.optionA, on && styles.active].filter((c) => c).join(' ')}
      >
        {labels[0]}
      </div>
      <div
        className={[styles.option, styles.optionB, !on && styles.active].filter((c) => c).join(' ')}
      >
        {labels[1]}
      </div>
    </div>
  );
}
