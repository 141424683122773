import styles from './Avatar.module.scss';

const emojis = ['😎', '🥸', '🤩', '🥳', '😏', '😋', '🤓', '😷', '😊', '😜'];

export default function Avatar({ user, size = '34px', className, style = {} }) {
  const emoji =
    emojis[
      (user?.id
        ?.split('')
        .map((char) => char.charCodeAt(0))
        .reduce((sum, v) => sum + v) ?? 0) % emojis.length
    ];
  return (
    <div
      style={{ width: size, height: size, lineHeight: size, ...style }}
      className={[styles.avatar, className].filter((x) => x).join(' ')}
    >
      {user?.avatarUrl ? (
        <img
          src={user.avatarUrl}
          alt={[user.firstName, user.lastName].filter((x) => x).join(' ')}
          style={{ width: '100%', height: '100%' }}
        />
      ) : (
        <div style={{ fontSize: parseInt(size) / 1.65 + 'px' }}>{emoji}</div>
      )}
    </div>
  );
}
