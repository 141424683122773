export default function Loading(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" {...props}>
      <path
        d="M36.521 20c.817 0 1.485-.663 1.418-1.477A18 18 0 1 0 31.64 33.73c.623-.528.62-1.47.042-2.048-.577-.577-1.51-.571-2.141-.053a15.042 15.042 0 1 1 5.428-13.106c.08.813.735 1.477 1.552 1.477Z"
        fill="#34AC68"
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          dur="1s"
          from="0 20 20"
          to="360 20 20"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  );
}
