import { DateTime, SystemZone } from 'luxon';

export const dateFormats = {
  dateShort: 'LLL. d, y',
  dateMedium: 'cccc, LLL. d',
  dateTimeShort: 'L/d/y h:mm a',
  dateTimeLong: "cccc, LLL. d, y 'at' h:mm a",
};

const currencyFormat = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2, // both minimum and maximum seem required for older Safari on iOS
  minimumFractionDigits: 2,
});

const currencyFormatNoCents = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0, // both minimum and maximum seem required for older Safari on iOS
  minimumFractionDigits: 0,
});

export const formatCurrency = (cents, options = {}) => {
  const opts = { cents: true, ...options };
  return (opts.cents ? currencyFormat : currencyFormatNoCents).format(cents / 100);
};

export const formatTimespan = (timespan) => {
  if (!timespan) return '';
  const { start, end, resolution } = timespan;
  return (
    start.toFormat('cccc, LLL. d, y') +
    (start.hasSame(end, resolution) ? '' : ' to ' + end.toFormat('cccc, LLL. d, y'))
  );
};

export const formatDate = (date, format = dateFormats.dateShort, timeZone) => {
  if (!date) return 'Invalid Date';
  let obj = date;
  if (typeof date === 'string') {
    obj = DateTime.fromISO(date);
  }
  if (timeZone) {
    obj = obj.setZone(timeZone);
  }
  const isSystemTimeZone = !timeZone || SystemZone.instance.name === obj.zone.name;
  return obj.toFormat(!isSystemTimeZone && format.endsWith('h:mm a') ? format + ' ZZZZ' : format);
};

export const formatHour = (hour) => {
  const shortHour = hour % 12;
  return (shortHour === 0 ? 12 : shortHour) + (hour < 12 || hour === 24 ? 'am' : 'pm');
};

// time is seconds since midnight
export const formatTime = (time) => {
  const hours = Math.floor(time / 60 / 60) % 12 || 12;
  const minutes = Math.floor((time / 60) % 60);
  return (
    hours + ':' + ((minutes < 10 ? '0' : '') + minutes) + (time / 60 / 60 < 12 ? ' AM' : ' PM')
  );
};

export const formatTimeRange = (range) => {
  if (!(Number.isInteger(range.startTime) && Number.isInteger(range.endTime))) return '';
  return formatTime(range.startTime) + ' to ' + formatTime(range.endTime);
};

export const formatPhone = (phone) => {
  if (typeof phone !== 'string') return '';
  const trimmed = phone?.replace(/^\+1/, '');
  if (trimmed.length !== 10) return phone;
  return `(${trimmed.substring(0, 3)}) ${trimmed.substring(3, 6)}-${trimmed.substring(6, 10)}`;
};

export const formatName = (user) => {
  if (!user) return '';
  return [user.firstName, user.lastName].filter(Boolean).join(' ');
};

export const sentenceCase = (str) => (str ? str[0].toUpperCase() + str.slice(1) : '');

const paymentTypes = {
  CARD_PRESENT: 'Card',
  CASH: 'Cash',
  LEGACY_ONLINE: 'Mobile App',
  ONLINE: 'Mobile App',
  REFUND: 'Refund',
};

export const formatPaymentType = (paymentType) => {
  if (!paymentType) return 'Unpaid';
  return paymentTypes[paymentType];
};
