import styles from './Button.module.scss';
import { Link } from 'react-router-dom';

export default function Button({
  children,
  className = styles.primary,
  active,
  loading,
  ...props
}) {
  const classes = [styles.button, className, active && 'active'].filter((s) => !!s).join(' ');
  return props.to ? (
    <Link className={classes} {...props}>
      {children}
    </Link>
  ) : (
    <button className={classes} {...props}>
      {children}
    </button>
  );
}
