import logo from 'theme/instamenu.svg';
import styles from './Footer.module.scss';
import { Link } from 'components/core/links';

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className="container">
        <Link to="/">
          <img src={logo} className="logo" alt="instamenu" />
        </Link>
      </div>
    </footer>
  );
}
