import { gql } from '@urql/core';

export const userQuery = gql`
  query CustomerUserQuery($userId: ID!) {
    customer(userId: $userId) {
      user {
        id
        email
        firstName
        lastName
        phone
      }
    }
  }
`;
