import styles from './ChartSection.module.scss';
import ChartEmpty from './ChartEmpty';
import ChartError from './ChartError';
import ChartLoading from './ChartLoading';

export default function ChartSection({ children, error, loading, empty, Header }) {
  return (
    <div className={styles.chartSection}>
      {Header}
      {error ? <ChartError /> : loading ? <ChartLoading /> : empty ? <ChartEmpty /> : children}
    </div>
  );
}
