import styles from './ChartError.module.scss';

export default function ChartError() {
  return (
    <div className={styles.error}>
      <div className={styles.emoji}>🤕</div>
      <div className={styles.title}>Yikes</div>
      <div className={styles.description}>There was an error loading the data.</div>
    </div>
  );
}
