import { useEffect, useState } from 'react';
import useDismissible from 'utils/useDismissible';
import selectStyles from 'components/core/forms/Select.module.scss';
import { RadioButton, TimeInput } from 'components/core/forms';
import { useMediaQuery } from 'react-responsive';
import { BottomDrawer } from 'components/core/modals';
import { Button } from 'components/core/buttons';
import { formatHour } from 'utils/format';
import styles from './CustomTimeRange.module.scss';
import breakpoints from 'theme/breakpoints.module.scss';
import { formatTimeRange } from 'utils/format';

export const allDay = { startTime: undefined, endTime: undefined };
const isValidRange = (r) => Number.isInteger(r.startTime) && Number.isInteger(r.endTime);

export default function CustomTimeRange({ restaurants, times, setTimes }) {
  const [range, setRange] = useState(times);
  const [visible, setVisible] = useState();
  const [radio, setRadio] = useState(isValidRange(range) ? 'custom' : 'day');
  const isDesktop = useMediaQuery({ query: `(min-width: ${breakpoints.mobile})` });
  const { ref } = useDismissible(() => {
    isDesktop && setVisible(false) && handleSubmit();
  });
  const handleTimeChange = (value) => {
    setRange(value);
  };
  useEffect(() => {
    if (radio === 'day') setTimes(allDay);
    else if (isValidRange(range)) setTimes(range);
  }, [radio, range, setTimes]);
  const resetTime = formatHour((restaurants?.[0]?.dayOffset ?? 0) / 60 / 60);
  const handleSubmit = (e) => {
    e?.preventDefault();
    if (isValidRange(range)) {
      setTimes(range);
    } else {
      setTimes(allDay);
    }
    setVisible(false);
  };
  const TimePickerComponent = (
    <form onSubmit={handleSubmit}>
      <div
        className={styles.option}
        onClick={() => {
          setRadio('day');
        }}
      >
        <div>
          <RadioButton selected={radio === 'day'} />
        </div>
        <div>
          <div className={styles.title}>All day</div>
          <div className={styles.subtitle}>
            {resetTime} to {resetTime}
          </div>
        </div>
      </div>
      <div
        className={styles.option}
        onClick={() => {
          setRadio('custom');
        }}
      >
        <div>
          <RadioButton selected={radio === 'custom'} />
        </div>
        <div>
          <div className={styles.title}>Custom</div>
          <div>
            <div className={styles.subtitle}>Start</div>
            <div>
              <TimeInput
                time={range.startTime}
                onChange={(value) => handleTimeChange({ ...range, startTime: value })}
              />
            </div>
          </div>
          <div>
            <div className={styles.subtitle}>End</div>
            <div>
              <TimeInput
                time={range.endTime}
                onChange={(value) => handleTimeChange({ ...range, endTime: value })}
              />
            </div>
          </div>
        </div>
      </div>
      <Button
        className={isDesktop && 'hidden'}
        type="submit"
        style={{ width: '100%', margin: '48px 0' }}
      >
        Done
      </Button>
    </form>
  );

  return (
    <div style={{ position: 'relative' }} ref={ref}>
      <div className={selectStyles.select} onClick={() => setVisible(true)}>
        {radio === 'day' || !isValidRange(range) ? 'All day' : formatTimeRange(range)}
      </div>

      {!isDesktop && visible && (
        <BottomDrawer onRequestClose={() => setVisible(false)}>{TimePickerComponent}</BottomDrawer>
      )}
      {isDesktop && visible && <div className={styles.dropdown}>{TimePickerComponent}</div>}
    </div>
  );
}
