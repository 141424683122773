import { gql } from '@urql/core';

export const dailySalesQuery = gql`
  query DailySalesQuery(
    $restaurantIds: [ID!]!
    $startDate: ISO8601Date!
    $endDate: ISO8601Date!
    $startTime: Int
    $endTime: Int
  ) {
    stats(
      restaurantIds: $restaurantIds
      startTime: $startTime
      endTime: $endTime
      startDate: $startDate
      endDate: $endDate
    ) {
      orders {
        id
        tip
        total
        subtotal
        paidAt
        restaurant {
          timeZone
        }
      }
    }
  }
`;
