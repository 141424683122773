import { useMemo } from 'react';
import { ChartSection, ChartHeader } from 'components/core/charts';
import { useQuery } from 'urql';
import { ordersQuery } from './queries';
import { ViewMoreTable } from 'components/core/tables';
import { dateFormats, formatCurrency, formatDate, formatPaymentType } from 'utils/format';
import { useUserContext } from 'contexts/user';
import { Link } from 'components/core/links';

export default function Orders({ userId }) {
  const { restaurants } = useUserContext();
  const variables = useMemo(() => ({ userId }), [userId]);
  const [{ data, error, fetching }] = useQuery({ query: ordersQuery, variables });
  const columns = useMemo(
    () =>
      [
        {
          Header: 'Order ID',
          accessor: 'number',
          width: '10%',
          Cell: ({ row, value }) => <Link to={`/orders/${row.original.id}`}>{value}</Link>,
        },
        {
          Header: 'Type',
          accessor: 'paymentType',
          width: '10%',
          Cell: ({ value }) => formatPaymentType(value),
        },
        restaurants.length > 1 && {
          Header: 'Location',
          accessor: 'restaurant.name',
          width: '10%',
        },
        {
          Header: 'Subtotal',
          accessor: 'subtotal',
          className: 'numeric',
          width: '10%',
          Cell: ({ value }) => formatCurrency(value),
        },
        {
          Header: 'Tax',
          accessor: 'tax',
          className: 'numeric',
          width: '10%',
          Cell: ({ value }) => formatCurrency(value),
        },
        {
          Header: 'Tip',
          accessor: 'tip',
          className: 'numeric',
          width: '10%',
          Cell: ({ value, row }) => (row.original.paymentType ? formatCurrency(value) : '—'),
        },
        {
          Header: 'Total',
          accessor: 'total',
          className: 'numeric',
          width: '10%',
          Cell: ({ value }) => formatCurrency(value),
        },
        {
          Header: 'Note',
          accessor: (row) =>
            [
              row.hasBonus && 'Bonus',
              row.totalDiscountAmount && 'Discount',
              row.hasRefund && 'Refunded',
            ]
              .filter((x) => x)
              .join(', '),
          width: '15%',
        },
        {
          Header: 'Placed At',
          accessor: 'placedAt',
          className: 'numeric',
          width: '15%',
          Cell: ({ value, row }) =>
            formatDate(value, dateFormats.dateTimeShort, row.original.restaurant.timeZone),
        },
      ].filter((x) => !!x),
    [restaurants.length]
  );

  return (
    <ChartSection
      error={error}
      loading={fetching}
      empty={!(data?.customer?.orders.length > 0)}
      Header={<ChartHeader title="Orders" />}
    >
      <ViewMoreTable
        data={data?.customer?.orders}
        columns={columns}
        initialState={{ sortBy: [{ id: 'paidAt', desc: true }] }}
      />
    </ChartSection>
  );
}
