import { useCallback } from 'react';
import styles from './TextInput.module.scss';

export default function Input({
  className,
  onChange = () => {},
  type = 'text',
  setIsActive,
  ...props
}) {
  return (
    <input
      className={[styles.input, className].filter((x) => x).join(' ')}
      type={type}
      onChange={useCallback((e) => onChange(e.target.value), [onChange])}
      {...props}
    />
  );
}
