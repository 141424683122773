import Filters from './Filters';
import Overview from './charts/overview/Overview';
import DailySales from './charts/daily-sales/DailySales';
import TopItems from './charts/top-items/TopItems';
import TopAddons from './charts/top-addons/TopAddons';
import Orders from './charts/orders/Orders';
import { SalesSummaryProvider } from './SalesSummaryContext';

export default function SalesSummary() {
  return (
    <SalesSummaryProvider>
      <main>
        <div className="container">
          <h1>Sales Summary</h1>
          <Filters />
          <Overview />
          <DailySales />
          <TopItems />
          <TopAddons />
          <Orders />
        </div>
      </main>
    </SalesSummaryProvider>
  );
}
