import styles from './Link.module.scss';
import { Link as ReactRouterDOMLink } from 'react-router-dom';

export default function Link({ className, ...props }) {
  return (
    <ReactRouterDOMLink
      className={[styles.link, className].filter((x) => x).join(' ')}
      {...props}
    />
  );
}
