import React from 'react';
import ReactDOM from 'react-dom';
import styles from './Modal.module.scss';

export default function Modal({ children, onRequestClose = () => {} }) {
  const doRequestClose = (e) => {
    if (e.target === e.currentTarget) onRequestClose(e);
  };
  return ReactDOM.createPortal(
    <React.StrictMode>
      <div className={styles.overlay} onClick={doRequestClose}>
        {children}
      </div>
    </React.StrictMode>,
    document.getElementById('root')
  );
}
