import { gql } from '@urql/core';

export const ordersQuery = gql`
  query CustomerOrdersQuery($userId: ID!) {
    customer(userId: $userId) {
      orders {
        id
        number
        restaurant {
          name
        }
        subtotal
        tax
        total
        tip
        placedAt
        paymentType
      }
    }
  }
`;
