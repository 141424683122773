import { useMemo } from 'react';
import { formatName, formatPhone } from 'utils/format';
import { Avatar } from 'components/core/avatar';
import Overview from './overview/Overview';
import { useQuery } from 'urql';
import { userQuery } from './queries';
import TopItems from './top-items/TopItems';
import Orders from './orders/Orders';

export default function Customer({ match }) {
  const userId = match.params.id;
  const variables = useMemo(() => ({ userId }), [userId]);
  const [{ data, error }] = useQuery({ query: userQuery, variables });

  if (error || (data && !data.customer?.user)) {
    return (
      <>
        <main style={{ padding: '5vh 0' }}>
          <div className="container">
            <h1>🤕 Oh-oh</h1>
            <p>We couldn’t find a customer with id {userId}</p>
          </div>
        </main>
      </>
    );
  }

  const user = data?.customer?.user;
  if (user) {
    return (
      <>
        <main>
          <div className="container">
            <h1>{formatName(user)}</h1>
            <div
              style={{
                display: 'flex',
                borderBottom: '1px solid #ddd',
                paddingBottom: '24px',
                marginBottom: '16px',
              }}
            >
              <Avatar user={user} style={{ marginRight: '16px' }} size={'48px'} />
              <div>
                <div>
                  <a href={`tel:${user.phone}`}>{formatPhone(user.phone)}</a>
                </div>
                <div>
                  <a href={`mailto:${user.email}`}>{user.email}</a>
                </div>
                {user.email && (
                  <div>
                    <span>Email Marketing Opt-in: </span>
                    <span>
                      {user.canMarket === true
                        ? 'Yes'
                        : user.canMarket === false
                        ? 'No'
                        : 'No selection yet'}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <Overview userId={match.params.id} />
            <TopItems userId={match.params.id} />
            <Orders userId={match.params.id} />
          </div>
        </main>
      </>
    );
  }

  return null;
}
