import { useState } from 'react';
import useDismissible from 'utils/useDismissible';
import selectStyles from 'components/core/forms/Select.module.scss';
import { useMediaQuery } from 'react-responsive';
import { BottomDrawer } from 'components/core/modals';
import { Button } from 'components/core/buttons';
import styles from './RestaurantSelection.module.scss';
import breakpoints from 'theme/breakpoints.module.scss';
import { Checkbox } from 'components/core/forms';

export default function RestaurantSelection({ restaurants, selection, onChange }) {
  const [visible, setVisible] = useState();
  const isDesktop = useMediaQuery({ query: `(min-width: ${breakpoints.mobile})` });
  const { ref } = useDismissible(() => {
    isDesktop && setVisible(false) && handleSubmit();
  });
  const handleSubmit = (e) => {
    e?.preventDefault();
    setVisible(false);
  };

  const RestaurantList = (
    <form
      onSubmit={handleSubmit}
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        width: '100%',
        maxHeight: '500px',
      }}
    >
      <div style={{ flexShrink: 1, overflowY: 'scroll' }}>
        {restaurants.map((restaurant) => (
          <div className={styles.option} key={restaurant.id}>
            <Checkbox
              checked={selection.includes(restaurant)}
              onChange={() => {
                if (selection.includes(restaurant)) {
                  onChange(selection.filter((selected) => selected !== restaurant));
                } else {
                  onChange([...selection, restaurant]);
                }
              }}
            >
              {restaurant.name}
            </Checkbox>
          </div>
        ))}
      </div>
      <Button
        className={isDesktop && 'hidden'}
        type="submit"
        style={{ width: '100%', margin: '48px 0', flexGrow: 0, flexShrink: 0 }}
      >
        Done
      </Button>
    </form>
  );

  return (
    <div style={{ position: 'relative' }} ref={ref}>
      <div className={selectStyles.select} onClick={() => setVisible(true)}>
        {selection.length === 0
          ? 'Select a location'
          : selection.length === 1
          ? selection[0].name
          : selection.length + ' Locations'}
      </div>

      {!isDesktop && visible && (
        <BottomDrawer onRequestClose={() => setVisible(false)}>{RestaurantList}</BottomDrawer>
      )}
      {isDesktop && visible && <div className={styles.dropdown}>{RestaurantList}</div>}
    </div>
  );
}
