import { gql } from '@urql/core';

export const overviewQuery = gql`
  query CustomerOverviewQuery($userId: ID!) {
    customer(userId: $userId) {
      averageSpent
      averageTipPercent
      firstOrder {
        placedAt
        restaurant {
          timeZone
        }
      }
      lastOrder {
        placedAt
        restaurant {
          timeZone
        }
      }
      numOrders
      spentAmount
    }
  }
`;
