import { useState } from 'react';
import Switch from './Switch';
import styles from './TimeInput.module.scss';

const canBecomeValid = (x) =>
  (x >= 0 && x <= 65) ||
  (x >= 70 && x <= 75) ||
  (x >= 80 && x <= 85) ||
  (x >= 90 && x <= 95) ||
  (x < 1000 && x % 100 < 60) ||
  (x >= 1000 && x <= 1059) ||
  (x >= 1100 && x <= 1159) ||
  (x >= 1200 && x <= 1259);

const intToTime = (x, period) => {
  if (!Number.isInteger(x)) return;
  if (x < 12) return (period === 'AM' ? x : x + 12) * 60 * 60; // 0-11 becomes X:00
  if (x === 12 && period === 'AM') return 0; // 12 becomes '12:00'
  if (x < 100) return period === 'AM' ? x * 60 : x + 720 * 60; // 75 becomes 1:15
  if (x < 1200)
    return Math.floor(x / 100) * 60 * 60 + (x % 100) * 60 + (period === 'PM' ? 720 * 60 : 0);
  if (x === 1200) return period === 'AM' ? 0 : 720 * 60;
  return (x % 100) * 60 + (period === 'PM' ? 720 * 60 : 0); //  1215 becomes 00:15
};

const timeToString = (time) => {
  if (typeof time !== 'number') return '';
  const hours = Math.floor(time / 3600) % 12;
  const minutes = Math.floor(time / 60) % 60;
  return (
    (hours === 0 ? '12' : (hours < 10 ? '0' : '') + hours) +
    ':' +
    ((minutes < 10 ? '0' : '') + minutes)
  );
};

const prettifyInput = (input) => {
  if (input.length === 4) return input.substr(0, 2) + ':' + input.substr(2, 2);
  if (input.length === 3) return input.substr(0, 1) + ':' + input.substr(1, 2);
  return input;
};

export default function TimeInput({ time, onChange = () => {}, ...props }) {
  const [value, setValue] = useState(timeToString(time));
  const [period, setPeriod] = useState(time >= 12 * 60 * 60 ? 'PM' : 'AM');

  const handleTimeChange = (input) => {
    if (!input) return setValue('');
    const inputNum = parseInt(input.replace(/\D/g, ''));
    if (canBecomeValid(inputNum)) setValue(prettifyInput(String(inputNum)));
    onChange(intToTime(parseInt(input.replace(/\D/g, '')), period));
  };

  const handlePeriodChange = (val) => {
    setPeriod(val);
    onChange(intToTime(parseInt(value.replace(/\D/g, '')), val));
  };

  const handleTimeBlur = (val) => {
    setValue(timeToString(intToTime(parseInt(val.replace(/\D/g, '')), period)));
    // onChange(intToTime(parseInt(val.replace(/\D/g, '')), period));
  };

  const handleFocus = (e) => setTimeout(() => e.target.select(), 0); // need to use setTimeout for Safari on OSX

  return (
    <div className={styles.timeInput}>
      <input
        className={styles.input}
        placeholder="00:00"
        type={'tel'}
        value={value}
        onChange={(e) => handleTimeChange(e.target.value)}
        onBlur={(e) => handleTimeBlur(e.target.value)}
        onFocus={handleFocus}
        {...props}
      />
      <Switch
        onChange={(on) => handlePeriodChange(on ? 'AM' : 'PM')}
        labels={['AM', 'PM']}
        on={period === 'AM'}
      />
    </div>
  );
}
