import { useMemo } from 'react';
import { ChartSection, ChartHeader } from 'components/core/charts';
import { useQuery } from 'urql';
import { topItemsQuery } from './queries';
import { ViewMoreTable } from 'components/core/tables';
import { formatCurrency } from 'utils/format';
import { useMediaQuery } from 'react-responsive';
import breakpoints from 'theme/breakpoints.module.scss';

export default function TopItems({ userId }) {
  const variables = useMemo(() => ({ userId }), [userId]);
  const isDesktop = useMediaQuery({ query: `(min-width: ${breakpoints.mobile})` });
  const [{ data, error, fetching }] = useQuery({ query: topItemsQuery, variables });
  const columns = useMemo(
    () => [
      { Header: 'Item Name', accessor: 'name', width: '50%' },
      {
        Header: isDesktop ? 'Quantity Sold' : 'Qty',
        accessor: 'quantity',
        width: '15%',
      },
      {
        Header: 'Total Sales',
        accessor: 'amount',
        className: 'numeric',
        width: '25%',
        Cell: ({ value }) => formatCurrency(value),
      },
    ],
    [isDesktop]
  );

  return (
    <ChartSection
      empty={!(data?.customer.topItems.length > 0)}
      loading={fetching}
      error={error}
      Header={<ChartHeader title="Top Items" />}
    >
      <ViewMoreTable
        data={data?.customer.topItems}
        columns={columns}
        initialState={{ sortBy: [{ id: 'quantity', desc: true }] }}
      />
    </ChartSection>
  );
}
