import { unparse } from 'papaparse';
import _ from 'lodash';
import { SecondaryButton } from 'components/core/buttons';

export default function CSVExportButton({ getData }) {
  const handleClick = () => {
    const { data, columns, filename } = getData();
    const str = unparse([columns, ...data], { header: false });
    const blob = new Blob([str], { type: 'text/csv' });
    const blobURL = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', blobURL);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return <SecondaryButton onClick={handleClick}>Export</SecondaryButton>;
}

export function tableCSVData({ columns, data, filename }) {
  return {
    data: data.map((row) =>
      columns.reduce((obj, column) => {
        const accessorValue =
          typeof column.accessor === 'function'
            ? column.accessor(row)
            : _.get(row, column.accessor);
        return {
          ...obj,
          [column.accessor]: column.CSVCell
            ? column.CSVCell({ value: accessorValue, row: { original: row } })
            : column.Cell
            ? column.Cell({ value: accessorValue, row: { original: row } })
            : accessorValue,
        };
      }, {})
    ),
    columns: columns.reduce(
      (acc, column) => ({
        ...acc,
        [column.accessor]: column.Header,
      }),
      {}
    ),
    filename,
  };
}
