import { gql } from '@urql/core';

export const customersQuery = gql`
  query CustomersQuery($restaurantIds: [ID!]) {
    customers(restaurantIds: $restaurantIds) {
      numOrders
      restaurants {
        name
      }
      spentAmount
      user {
        id
        name
        email
        phone
      }
    }
  }
`;
