import { useEffect, useState } from 'react';
import { getAPIToken } from 'contexts/firebase';
import { cacheExchange, createClient, dedupExchange, fetchExchange } from 'urql';
import { fromPromise, map, mergeMap, pipe } from 'wonka';
import { makeOperation } from '@urql/core';
import { Provider as GraphQLProvider } from 'urql';
import { useAuthContext } from 'contexts/firebase';

const fn = async (fetchOptions) => {
  const token = await getAPIToken();
  if (token) {
    fetchOptions.headers = fetchOptions.headers ?? {};
    fetchOptions.headers.Authorization = `Bearer ${token}`;
  }
  return fetchOptions;
};

export const firebaseAuthExchange =
  ({ forward }) =>
  (operations$) => {
    return pipe(
      operations$,
      mergeMap((operation) => {
        const currentOptions =
          typeof operation.context.fetchOptions === 'function'
            ? operation.context.fetchOptions()
            : operation.context.fetchOptions || {};
        return pipe(
          fromPromise(fn(currentOptions)),
          map((fetchOptions) => {
            return makeOperation(operation.kind, operation, {
              ...operation.context,
              fetchOptions,
            });
          })
        );
      }),
      forward
    );
  };

export const InstamenuAPIProvider = ({ children }) => {
  const { isAuthenticated } = useAuthContext();
  const [client, setClient] = useState();

  useEffect(() => {
    // recreate the client when isAuthenticated changes to clear the cache
    setClient(
      createClient({
        url: `${process.env.REACT_APP_API_ENDPOINT}/stats/graphql`,
        exchanges: [dedupExchange, cacheExchange, firebaseAuthExchange, fetchExchange],
      })
    );
  }, [isAuthenticated]);

  if (!client) return null;
  return <GraphQLProvider value={client}>{children}</GraphQLProvider>;
};
