import { useEffect, useState, useCallback } from 'react';
import { VictoryChart, VictoryContainer } from 'victory';

export const useContainerDimensions = () => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [node, setNode] = useState();

  const getDimensions = useCallback(
    () => ({
      width: node.offsetWidth,
      height: node.offsetHeight,
    }),
    [node]
  );

  useEffect(() => {
    const handleResize = () => setDimensions(getDimensions());
    if (node) setDimensions(getDimensions());
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [getDimensions, node]);

  return [setNode, dimensions];
};

export default function ResponsiveVictoryChart({ children, minWidth = 800, ...props }) {
  const [setNode, dimensions] = useContainerDimensions();
  return (
    <div style={{ width: '100%', overflowX: 'auto', overflowY: 'hidden' }}>
      <VictoryChart
        containerComponent={
          <VictoryContainer
            containerRef={setNode}
            style={{
              width: '100%',
              minWidth: minWidth + 'px',
              height: '300px',
              userSelect: 'none',
              pointerEvents: 'none',
              touchAction: 'auto',
              position: 'relative',
            }}
          />
        }
        width={dimensions.width}
        height={dimensions.height}
        {...props}
      >
        {children}
      </VictoryChart>
    </div>
  );
}
