import styles from './Select.module.scss';
import { useState } from 'react';
import useDismissible from 'utils/useDismissible';
import selectStyles from 'components/core/forms/Select.module.scss';
import { useMediaQuery } from 'react-responsive';
import { BottomDrawer } from 'components/core/modals';
import breakpoints from 'theme/breakpoints.module.scss';
import { ReactComponent as Checkmark } from './checkmark.svg';

export default function Select({
  label = (option) => option.label,
  id = (option) => option.id ?? option.label ?? option,
  options,
  onChange,
  value,
}) {
  const [visible, setVisible] = useState();
  const isDesktop = useMediaQuery({ query: `(min-width: ${breakpoints.mobile})` });
  const { ref } = useDismissible(() => {
    isDesktop && setVisible(false);
  });

  const Options = (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        width: '100%',
        maxHeight: '500px',
      }}
    >
      <div style={{ flexShrink: 1, overflowY: 'scroll' }}>
        {options.map((option, index) => (
          <div
            className={styles.option}
            key={id(option) ?? index}
            onClick={() => {
              onChange(option);
              setVisible(false);
            }}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <span>{label(option)}</span>
            {id(option) === id(value) && <Checkmark />}
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div style={{ position: 'relative' }} ref={ref}>
      <div className={selectStyles.select} onClick={() => setVisible(true)}>
        {label(value)}
      </div>
      {!isDesktop && visible && (
        <BottomDrawer onRequestClose={() => setVisible(false)}>{Options}</BottomDrawer>
      )}
      {isDesktop && visible && <div className={styles.dropdown}>{Options}</div>}
    </div>
  );
}
