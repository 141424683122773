import { createContext, useEffect, useContext, useMemo, useState } from 'react';
import { allDay } from 'components/filters/CustomTimeRange';
import { useUserContext } from 'contexts/user';
import { DateTime } from 'luxon';
import { useQuery } from 'urql';
import { debounce } from 'lodash';

function fromLocalStorage() {
  try {
    return JSON.parse(localStorage.getItem('filters'));
  } catch (ex) {
    return;
  }
}

const today = DateTime.now().startOf('day');
export const timespans = [
  {
    label: 'Today',
    start: today,
    end: today,
    resolution: 'day',
    comparisonStart: today.minus({ days: 7 }),
    comparisonEnd: today.minus({ days: 7 }),
    comparisonLabel: 'a week ago',
  },
  {
    label: 'Yesterday',
    start: today.minus({ days: 1 }),
    end: today.minus({ days: 1 }),
    comparisonStart: today.minus({ days: 8 }),
    comparisonEnd: today.minus({ days: 8 }),
    comparisonLabel: 'the same day last week',
    resolution: 'day',
  },
  {
    label: 'Last 7 Days',
    start: today.minus({ days: 6 }),
    end: today,
    resolution: 'day',
    comparisonStart: today.minus({ days: 14 }),
    comparisonEnd: today.minus({ days: 7 }),
    comparisonLabel: 'the previous 7 days',
  },
  {
    label: 'Week to Date',
    start: today.startOf('week'),
    end: today,
    resolution: 'day',
    comparisonStart: today.startOf('week').minus({ weeks: 1 }),
    comparisonEnd: today.minus({ weeks: 1 }),
    comparisonLabel: 'the same period in the previous week',
  },
  {
    label: 'Last 30 Days',
    start: today.minus({ days: 29 }),
    end: today,
    resolution: 'day',
    comparisonStart: today.minus({ days: 59 }),
    comparisonEnd: today.minus({ days: 30 }),
    comparisonLabel: 'the previous 30 days',
  },
  {
    label: 'Month to Date',
    start: today.startOf('month'),
    end: today,
    resolution: 'day',
    comparisonStart: today.startOf('month').minus({ months: 1 }),
    comparisonEnd: today.minus({ months: 1 }),
    comparisonLabel: 'the same period in the previous month',
  },
  { id: 'Custom', label: 'Custom', resolution: 'day' },
];

export const SalesSummaryContext = createContext();

export const SalesSummaryProvider = ({ children }) => {
  const { restaurants: userRestaurants } = useUserContext();
  const [restaurants, setRestaurantsState] = useState(
    fromLocalStorage()
      ?.restaurants?.map((id) => userRestaurants.find((r) => id === r.id))
      .filter((x) => !!x) ?? userRestaurants
  );
  const setRestaurants = (value) => {
    const existing = fromLocalStorage() ?? {};
    localStorage?.setItem(
      'filters',
      JSON.stringify({ ...existing, restaurants: value.map(({ id }) => id) })
    );
    setRestaurantsState(value);
  };

  const [timespan, setTimespanState] = useState(() => {
    const existing = fromLocalStorage()?.timespan;
    if (!existing) return timespans[0];
    if (existing.id === 'Custom')
      return {
        ...existing,
        start: DateTime.fromISO(existing.start),
        end: DateTime.fromISO(existing.end),
      };
    return timespans.find((t) => t.label === existing.label) ?? timespans[0];
  });
  const setTimespan = (value) => {
    const existing = fromLocalStorage() ?? {};
    localStorage?.setItem('filters', JSON.stringify({ ...existing, timespan: value }));
    setTimespanState(value);
  };

  const [times, setTimesState] = useState(fromLocalStorage()?.times ?? allDay);
  const setTimes = (value) => {
    const existing = fromLocalStorage() ?? {};
    localStorage?.setItem('filters', JSON.stringify({ ...existing, times: value }));
    setTimesState(value);
  };
  const statsQueryVariables = useMemo(
    () => ({
      restaurantIds: restaurants.map(({ id }) => id),
      startDate: timespan.start.toISODate(),
      endDate: timespan.end.toISODate(),
      ...times,
    }),
    [restaurants, times, timespan]
  );
  const useStatsQuery = ({ query, variables, pause }) => {
    const debounceOptions = useMemo(() => ({ leading: true }), []);
    const queryVars = useDebounce(variables ?? statsQueryVariables, 1000, debounceOptions);
    const executeQuery = queryVars.restaurantIds.length > 0;
    const res = useQuery({ query, variables: queryVars, pause: !executeQuery || pause });
    return executeQuery ? res : [{ data: null, error: null, fetching: false }];
  };
  const value = {
    useStatsQuery,
    statsQueryVariables,
    restaurants,
    setRestaurants,
    timespan,
    setTimespan,
    times,
    setTimes,
  };
  return <SalesSummaryContext.Provider value={value}>{children}</SalesSummaryContext.Provider>;
};

export function useSalesSummaryContext() {
  return useContext(SalesSummaryContext);
}

const useDebounce = (value, delay, options) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const debouncedSetValue = useMemo(
    () => debounce(setDebouncedValue, delay, options),
    [delay, options]
  );
  useEffect(() => {
    debouncedSetValue(value);
  }, [debouncedSetValue, value]);
  return debouncedValue;
};
