import { gql } from '@urql/core';

export const ordersQuery = gql`
  query OrdersQuery(
    $restaurantIds: [ID!]!
    $startDate: ISO8601Date!
    $endDate: ISO8601Date!
    $startTime: Int
    $endTime: Int
  ) {
    stats(
      restaurantIds: $restaurantIds
      startTime: $startTime
      endTime: $endTime
      startDate: $startDate
      endDate: $endDate
    ) {
      orders {
        id
        number
        paymentType
        subtotal
        tax
        tip
        total
        paidAt
        totalDiscountAmount
        hasBonus
        hasRefund
        refund {
          id
          subtotal
          tax
          tip: serviceFee
          processingFee
          total
          createdAt
        }
        reaction {
          isPositive
          reasons {
            text
          }
          note
        }
        restaurant {
          name
          timeZone
        }
      }
    }
  }
`;
