import { useMemo } from 'react';
import { useQuery } from 'urql';
import {
  dateFormats,
  formatDate,
  formatName,
  formatPaymentType,
  formatPhone,
  sentenceCase,
} from 'utils/format';
import { Avatar } from 'components/core/avatar';
import { Link } from 'components/core/links';
import OrderTable from './OrderTable';
import { orderQuery } from './queries';
import { SecondaryButton } from 'components/core/buttons';
import styles from './index.module.scss';

export default function Order({ match }) {
  const id = match.params.id;
  const variables = useMemo(() => ({ id }), [id]);
  const [{ data, error }] = useQuery({ query: orderQuery, variables });

  if (error || (data && !data.order)) {
    return (
      <>
        <main style={{ padding: '5vh 0' }}>
          <div className="container">
            <h1>🤕 Oh-oh</h1>
            <p>We couldn’t find an order with id {id}</p>
          </div>
        </main>
      </>
    );
  }

  const order = data?.order;
  if (order) {
    return (
      <>
        <main>
          <div className="container">
            <div style={{ maxWidth: '500px' }}>
              <h1>Order #{order.number}</h1>
              {order.user && (
                <div
                  style={{
                    display: 'flex',
                    borderBottom: '1px solid #ddd',
                    paddingBottom: '24px',
                    marginTop: '32px',
                  }}
                >
                  <Avatar user={order.user} style={{ marginRight: '16px' }} size={'48px'} />
                  <div>
                    <div>
                      <Link to={`/customers/${order.user.id}`}>{formatName(order.user)}</Link>
                    </div>
                    <div>
                      <a href={`tel:${order.user.phone}`}>{formatPhone(order.user.phone)}</a>
                    </div>
                    <div>
                      <a href={`mailto:${order.user.email}`}>{order.user.email}</a>
                    </div>
                  </div>
                </div>
              )}
              {order.reaction && (
                <div style={{ marginTop: '32px' }}>
                  <h2>Customer Feedback</h2>
                  <div className={styles.reaction}>
                    <div className={styles.reactionEmoji}>
                      {order.reaction.isPositive ? '😀' : '😡'}
                    </div>
                    <div>
                      <div className={styles.reactionReasons}>
                        {order.reaction.reasons.map(({ text }) => text).join(', ')}
                      </div>
                      <div className={styles.reactionNote}>{order.reaction.note}</div>
                    </div>
                  </div>
                </div>
              )}
              <div style={{ marginTop: '32px' }}>
                <h2>Summary</h2>
                <div>
                  {formatDate(order.placedAt, dateFormats.dateTimeLong, order.restaurant.timeZone)}
                </div>
                <div>{order.restaurant.name}</div>
                <div>{formatPaymentType(order.paymentType)}</div>
                {order.paymentMethod && (
                  <div>
                    {sentenceCase(order.paymentMethod.brand)} ending in {order.paymentMethod.last4}
                  </div>
                )}
              </div>
              <div style={{ marginTop: '32px', marginBottom: '48px' }}>
                <h2>Receipt</h2>
                <OrderTable order={order} />
              </div>
              {order.paymentType && (
                <div style={{ marginTop: '32px', marginBottom: '48px' }}>
                  <SecondaryButton to={`/orders/${order.id}/refund`}>Issue Refund</SecondaryButton>
                </div>
              )}
            </div>
          </div>
        </main>
      </>
    );
  }
  return null;
}
