import { gql } from '@urql/core';

export const orderQuery = gql`
  query OrderQuery($id: ID!) {
    order(id: $id) {
      id
      number
      placedAt
      restaurant {
        name
        timeZone
      }
      reaction {
        isPositive
        note
        reasons {
          text
        }
      }
      paymentMethod {
        id
        brand
        last4
      }
      paymentType
      orderItems {
        id
        quantity
        item {
          name
        }
        itemRefund {
          quantity
        }
        modifiers {
          name
          price
        }
        price
      }
      discount {
        name
      }
      user {
        id
        firstName
        lastName
        email
        phone
      }
      grossSubtotal
      discountAmount
      totalDiscountAmount
      paymentStatus
      tip
      tax
      processingFee
      total
      refund {
        total
      }
    }
  }
`;
