import { usePagination, useSortBy, useTable, useExpanded } from 'react-table';
import styles from './Table.module.scss';
import viewMoreStyles from './ViewMoreTable.module.scss';

export default function ViewMoreTable({ data, columns, initialState = {}, getSubRows = () => [] }) {
  const tableInstance = useTable(
    {
      columns,
      data,
      getSubRows,
      initialState: { pageSize: 10, ...initialState },
      disableSortRemove: true,
    },
    useSortBy,
    useExpanded,
    usePagination
  );
  const {
    canNextPage,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setPageSize,
    state: { pageSize },
  } = tableInstance;

  const handleViewMore = () => setPageSize(pageSize + 10);

  return (
    <div>
      <div className={styles.scrollWrap}>
        <table {...getTableProps()} className={styles.table}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps({
                      ...column.getSortByToggleProps(),
                      className: [
                        column.className,
                        column.isSorted
                          ? column.isSortedDesc
                            ? styles.descending
                            : styles.ascending
                          : false,
                      ]
                        .filter((x) => x)
                        .join(' '),
                    })}
                    style={column.width ? { width: column.width } : null}
                  >
                    {column.render('Header')}
                    <span
                      className={[
                        styles.arrows,
                        column.isSorted &&
                          (column.isSortedDesc ? styles.descending : styles.ascending),
                      ]
                        .filter((x) => x)
                        .join(' ')}
                    />
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps(row.original.rowProps ?? {})}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps({
                          className: cell.column.className,
                        })}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div>
        {canNextPage && (
          <div className={viewMoreStyles.viewMore} onClick={handleViewMore}>
            View more
          </div>
        )}
      </div>
    </div>
  );
}
