import { useState } from 'react';
import { Select } from 'components/core/forms';
import styles from './Filters.module.scss';
import { useSalesSummaryContext, timespans } from './SalesSummaryContext';
import CustomDateRange from 'components/filters/CustomDateRange';
import CustomTimeRange from 'components/filters/CustomTimeRange';
import RestaurantSelection from 'components/filters/RestaurantSelection';
import { useUserContext } from 'contexts/user';

export default function Filters() {
  const { restaurants: userRestaurants } = useUserContext();
  const {
    restaurants: selectedRestaurants,
    setRestaurants,
    setTimespan,
    setTimes,
    timespan,
    times,
  } = useSalesSummaryContext();
  const [selectingCustomTimespan, setSelectingCustomTimespan] = useState(false);
  const handleTimespanChange = (value) => {
    setSelectingCustomTimespan(value.label === 'Custom');
    if (value.label !== 'Custom') setTimespan(value);
  };
  const handleCustomDateChange = (value) => {
    setTimespan({ id: 'Custom', resolution: 'day', ...value });
  };

  return (
    <div className={styles.filters}>
      <div className={styles.row}>
        <div className={styles.filter}>
          <RestaurantSelection
            restaurants={userRestaurants}
            selection={selectedRestaurants}
            onChange={setRestaurants}
          />
        </div>
        <div className={styles.filter}>
          <Select
            options={timespans}
            label={(timespan) => timespan.label}
            onChange={handleTimespanChange}
            value={
              timespan.id === 'Custom' || selectingCustomTimespan
                ? { id: 'Custom', label: 'Custom' }
                : timespan
            }
          />
        </div>
        {(selectingCustomTimespan || timespan?.id === 'Custom') && (
          <div className={styles.filter}>
            <CustomDateRange value={timespan} onChange={handleCustomDateChange} />
          </div>
        )}
        <div className={styles.filter}>
          <CustomTimeRange restaurants={selectedRestaurants} times={times} setTimes={setTimes} />
        </div>
      </div>
    </div>
  );
}
