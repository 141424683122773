import { gql } from '@urql/core';

export const createRefundMutation = gql`
  mutation CreateRefund($orderId: ID!, $orderItemIds: [ID!], $total: Int, $refundTip: Boolean) {
    createRefund(
      input: {
        orderId: $orderId
        orderItemIds: $orderItemIds
        total: $total
        refundTip: $refundTip
      }
    ) {
      refund {
        id
        order {
          id
          orderItems {
            id
            itemRefund {
              id
              quantity
            }
          }
          refund {
            id
            total
          }
        }
      }
    }
  }
`;

export const orderQuery = gql`
  query OrderQuery($id: ID!) {
    order(id: $id) {
      id
      orderItems {
        id
        quantity
        item {
          id
          name
        }
        modifiers {
          id
          name
        }
        price
        refundableItemTotal
      }
      number
      paymentType
      refundableStatus
      tip
      total
    }
  }
`;
