import React from 'react';
import FirebaseAuth from 'react-firebaseui/FirebaseAuth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import styles from './SignIn.module.scss';
import { Redirect } from 'react-router-dom';
import { useAuthContext } from 'contexts/firebase';

const uiConfig = {
  signInFlow: 'popup',
  signInOptions: [
    {
      provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      recaptchaParameters: {
        size: 'invisible', // 'invisible' or 'compact'
        badge: 'bottomright', //' bottomright' or 'inline' applies to invisible
      },
    },
  ],
  signInSuccessUrl: '/',
};

export default function SignIn({ location }) {
  const { isAuthenticated } = useAuthContext();
  if (isAuthenticated) {
    return <Redirect to={location.state?.from ?? uiConfig.signInSuccessUrl} />;
  }
  return (
    <div className={styles.screen}>
      <FirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
      <div className={styles.madeIn}>
        <div className={styles.emoji}>🤠</div>
        <div>Made in Austin</div>
      </div>
    </div>
  );
}
