import { useControllableState } from './useControllableState';
import TextInput from './TextInput';
import styles from './CurrencyInput.module.scss';

const centsToString = (value) => (Number.isInteger(value) ? value.toString() : '');
const stringToCents = (input) => (input ? parseInt(input) : null);

export default function CurrencyInput({
  className,
  onChange = () => {},
  style: styleProp = {},
  value: propValue,
  ...props
}) {
  const [input, setInput] = useControllableState(propValue, onChange, stringToCents, centsToString);
  function handleKeyDown(e) {
    if (e.nativeEvent.key === 'Backspace') {
      if (input === '') return;
      else {
        setInput(input.substr(0, input.length - 1));
      }
    } else if (input === '' && e.nativeEvent.key === '0') {
      return;
    } else if (e.nativeEvent.key.match(/\d/)) {
      setInput(input + e.nativeEvent.key);
    }
  }

  const parts = [];
  parts[1] = input ? input.replace(/(\d+)(\d\d)$/, '$1.$2') : '';
  parts[0] = '0.00'.substring(0, 4 - parts[1].length);

  return (
    <div className={styles.container}>
      <TextInput
        className={className}
        style={{ textAlign: 'right', width: '100%' }}
        onKeyDown={handleKeyDown}
        maxLength={0} // prevents input flickering
        {...props}
        placeholder={null}
      />
      <div className={styles.placeholder}>
        <span className={styles.symbol}>$ </span>
        <span className={styles.pad}>{parts[0]}</span>
        <span>{parts[1]}</span>
      </div>
    </div>
  );
}
