import { createContext, useContext } from 'react';
import { useQuery } from 'urql';
import { gql } from '@urql/core';
import { H } from 'highlight.run';
import { useAuthContext } from 'contexts/firebase';
import { Loading } from 'components/icons';

export const UserContext = createContext();

const meQuery = gql`
  query MeQuery {
    me {
      id
      firstName
      lastName
      avatarUrl
      employers(permission: REPORTING) {
        id
        name
        dayOffset
      }
    }
  }
`;

export const UserProvider = ({ children }) => {
  const { isAuthenticated } = useAuthContext();
  const [{ data, error, fetching }] = useQuery({ query: meQuery, pause: !isAuthenticated });
  const isAuthorized = isAuthenticated && !error && data && data.me && data.me.employers.length > 0;

  if (fetching)
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loading width={32} height={32} />
      </div>
    );

  const value = { isAuthorized, restaurants: data?.me?.employers ?? [], user: data?.me };
  if (value.user) {
    H.identify(value.user.id, {
      highlightDisplayName: [value.user.firstName, value.user.lastName].join(' '),
    });
  }
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export function useUserContext() {
  return useContext(UserContext);
}
