import React from 'react';
import Modal from './Modal';
import styles from './BottomDrawer.module.scss';

export default function BottomDrawer({ children, onRequestClose }) {
  return (
    <Modal onRequestClose={onRequestClose}>
      <div className={styles.drawer}>{children}</div>
    </Modal>
  );
}
