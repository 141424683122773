import { useState } from 'react';
import { useAuthContext } from 'contexts/firebase';
import { LinkButton } from 'components/core/links';
import useDismissible from 'utils/useDismissible';
import styles from './MainMenu.module.scss';
import { ReactComponent as Icon } from './menu.svg';

export default function MainMenu() {
  const { isAuthenticated } = useAuthContext();
  const [visible, setVisible] = useState(false);
  const { ref } = useDismissible(setVisible);
  if (!isAuthenticated) return null;
  return (
    <div ref={ref} style={{ position: 'relative', marginRight: 24 }}>
      <div
        className={[styles.button, visible && styles.active].filter((x) => x).join(' ')}
        onClick={() => setVisible(!visible)}
      >
        <Icon />
      </div>
      <div className={styles.dropdown} style={{ display: visible ? 'block' : 'none' }}>
        <div>
          <LinkButton to="/">Sales Summary</LinkButton>
        </div>
        <div>
          <LinkButton to="/customers">Customers</LinkButton>
        </div>
      </div>
    </div>
  );
}
