import { useState } from 'react';
import { useAuthContext } from 'contexts/firebase';
import { useUserContext } from 'contexts/user';
import { Avatar } from 'components/core/avatar';
import { LinkButton } from 'components/core/links';
import useDismissible from 'utils/useDismissible';
import styles from './UserMenu.module.scss';

export default function UserMenu() {
  const { user } = useUserContext();
  const { isAuthenticated, signOut } = useAuthContext();
  const [visible, setVisible] = useState(false);
  const handleSignOut = (e) => {
    e.preventDefault();
    setVisible(false);
    signOut();
  };
  const { ref } = useDismissible(setVisible);
  if (!isAuthenticated || !user) return null;
  return (
    <div ref={ref} style={{ position: 'relative' }}>
      <div
        className={[styles.menu, visible && styles.active].filter((x) => x).join(' ')}
        onClick={() => setVisible(!visible)}
      >
        <Avatar user={user} className={styles.avatar} />
        <div className={styles.name}>
          {[user.firstName, user.lastName].filter((x) => x).join(' ')}
        </div>
      </div>
      <div className={styles.dropdown} style={{ display: visible ? 'block' : 'none' }}>
        <LinkButton to="/" onClick={handleSignOut}>
          Sign out
        </LinkButton>
      </div>
    </div>
  );
}
