import { useMemo, useCallback } from 'react';
import { ChartSection, ChartHeader } from 'components/core/charts';
import { ordersQuery } from './queries';
import { ViewMoreTable } from 'components/core/tables';
import {
  dateFormats,
  formatDate,
  formatCurrency,
  formatPaymentType,
  formatTimeRange,
  formatTimespan,
} from 'utils/format';
import { useSalesSummaryContext } from '../../SalesSummaryContext';
import { Link } from 'components/core/links';
import styles from './Orders.module.scss';

export default function Orders() {
  const { restaurants, useStatsQuery, timespan, times } = useSalesSummaryContext();
  const [{ data, error, fetching }] = useStatsQuery({ query: ordersQuery });
  const columns = useMemo(
    () =>
      [
        {
          Header: 'Order ID',
          accessor: 'number',
          width: '10%',
          Cell: ({ value, row }) => <Link to={`/orders/${row.original.id}`}>{value}</Link>,
          CSVCell: ({ value, row }) => value,
        },
        {
          Header: 'Type',
          accessor: 'paymentType',
          width: '10%',
          Cell: ({ value }) => formatPaymentType(value),
        },
        restaurants.length > 1 && {
          Header: 'Location',
          accessor: 'restaurant.name',
          width: '10%',
        },
        {
          Header: 'Subtotal',
          accessor: 'subtotal',
          className: 'numeric',
          width: '10%',
          Cell: ({ value }) => formatCurrency(value),
        },
        {
          Header: 'Tax',
          accessor: 'tax',
          className: 'numeric',
          width: '10%',
          Cell: ({ value }) => formatCurrency(value),
        },
        {
          Header: 'Tip',
          accessor: 'tip',
          className: 'numeric',
          width: '10%',
          Cell: ({ value }) => formatCurrency(value),
        },
        {
          Header: 'Total',
          accessor: 'total',
          className: 'numeric',
          width: '10%',
          Cell: ({ value }) => formatCurrency(value),
        },
        {
          Header: 'Note',
          accessor: (row) =>
            [
              row.hasBonus && 'Bonus',
              row.totalDiscountAmount && 'Discount',
              row.hasRefund && 'Refunded',
            ]
              .filter((x) => x)
              .join(', '),
          Cell: ({ value, row }) =>
            row.canExpand ? (
              <span {...row.getToggleRowExpandedProps({})}>
                {row.isExpanded ? '➖' : '➕'} {value}
              </span>
            ) : (
              value
            ),
          width: '15%',
        },
        {
          Header: '🤔',
          accessor: 'reaction',
          width: '5%',
          Cell: ({ value }) => (!value ? '' : value.isPositive ? '😀' : '😡'),
          CSVCell: ({ value }) =>
            !value
              ? ''
              : (value.isPositive ? '😀' : '😡') +
                (value.reasons?.length > 0
                  ? ' ' + value.reasons?.map(({ text }) => text).join(', ')
                  : '') +
                (value.note ? '; ' + value.note : ''),
        },
        {
          Header: 'Paid At',
          accessor: 'paidAt',
          className: 'numeric',
          width: '15%',
          Cell: ({ value, row }) => {
            return formatDate(value, dateFormats.dateTimeShort, row.original.restaurant.timeZone);
          },
        },
      ].filter((x) => !!x),
    [restaurants.length]
  );

  const getRefundRow = useCallback(
    (order) =>
      order.refund
        ? [
            {
              id: order.id,
              number: order.number,
              paymentType: 'REFUND',
              paidAt: order.refund.createdAt,
              restaurant: order.restaurant,
              tip: -order.refund.tip,
              subtotal: -order.refund.subtotal,
              tax: -order.refund.tax,
              total: -order.refund.total,
              rowProps: {
                className: styles.refund,
              },
            },
          ]
        : [],
    []
  );

  const exportData = useMemo(
    () => data?.stats.orders.flatMap((order) => [order, getRefundRow(order)[0]]).filter((x) => x),
    [getRefundRow, data?.stats.orders]
  );
  return (
    <ChartSection
      error={error}
      loading={fetching}
      empty={!(data?.stats.orders.length > 0)}
      Header={
        <ChartHeader
          title="Orders"
          subtitle={[formatTimespan(timespan), formatTimeRange(times)].filter((x) => x).join('; ')}
          CSVData={
            exportData
              ? {
                  data: exportData,
                  columns,
                  filename: 'orders.csv',
                }
              : exportData
          }
        />
      }
    >
      <ViewMoreTable
        data={data?.stats.orders}
        getSubRows={getRefundRow}
        columns={columns}
        initialState={{ sortBy: [{ id: 'paidAt', desc: true }] }}
      />
    </ChartSection>
  );
}
