import { useMemo } from 'react';
import { ChartSection, ChartHeader } from 'components/core/charts';
import { DateTime } from 'luxon';
import { useQuery } from 'urql';
import { overviewQuery } from './queries';
import { dateFormats, formatCurrency, formatDate } from 'utils/format';
import styles from './Overview.module.scss';

export default function Overview({ userId }) {
  const variables = useMemo(() => ({ userId }), [userId]);
  const [{ data, error, fetching }] = useQuery({ query: overviewQuery, variables });
  return (
    <ChartSection
      error={error}
      loading={fetching}
      empty={!data?.customer}
      Header={<ChartHeader title="Overview" />}
    >
      <ChartData data={data?.customer} />
    </ChartSection>
  );
}

function Tile({ children, label, value }) {
  return (
    <div className={styles.tile}>
      <div className={styles.label}>{label}</div>
      <div className={styles.value}>{value ?? children}</div>
    </div>
  );
}

function prettyFrequency(start, end, numOrders) {
  if (!start || !end || !numOrders) return null;
  const days = start.diff(end, ['days']).toObject().days;
  const frequency = days / numOrders;
  return frequency < 2
    ? 'Daily'
    : frequency < 3.5
    ? 'Almost Daily'
    : frequency < 15.25
    ? 'Weekly'
    : frequency < 45.75
    ? 'Monthly'
    : 'Seldomly';
}

function ChartData({ data }) {
  const frequency = prettyFrequency(
    DateTime.fromISO(data.firstOrder.placedAt),
    DateTime.now(),
    data.numOrders
  );
  return (
    <div className={styles.tiles}>
      <Tile
        label="First Order"
        value={formatDate(
          data.firstOrder?.placedAt,
          dateFormats.dateMedium,
          data.firstOrder?.restaurant.timeZone
        )}
      />
      <Tile
        label="Last Order"
        value={formatDate(
          data.lastOrder?.placedAt,
          dateFormats.dateMedium,
          data.lastOrder?.restaurant.timeZone
        )}
      />
      <Tile label="Frequency" value={frequency} />
      <Tile label="Average Spend" value={formatCurrency(data.averageSpent)} />
      <Tile label="Orders" value={data.numOrders} />
      <Tile label="Total Spend" value={formatCurrency(data.spentAmount)} />
      <Tile label="Average Tip" value={`${data.averageTipPercent?.toFixed(2)}%`} />
    </div>
  );
}
