import logo from 'theme/instamenu.svg';
import styles from './Header.module.scss';
import MainMenu from './MainMenu';
import UserMenu from './UserMenu';
import { Link } from 'components/core/links';

export default function Header() {
  return (
    <header className={styles.header}>
      <div className={`container ${styles.container}`}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <MainMenu />
          <Link to="/">
            <img src={logo} className={styles.logo} alt="instamenu" />
          </Link>
        </div>
        <UserMenu />
      </div>
    </header>
  );
}
