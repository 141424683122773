export default function RadioButton({ selected }) {
  return selected ? (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="13" cy="13" r="12" fill="white" stroke="#34AC68" strokeWidth="2" />
      <circle cx="13" cy="13" r="9" fill="#34AC68" />
    </svg>
  ) : (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="13" cy="13" r="12" fill="white" stroke="#C5C4C4" strokeWidth="2" />
    </svg>
  );
}
