import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { history, PrivateRoute } from 'routes';
import { AuthProvider } from 'contexts/firebase';
import { UserProvider } from 'contexts/user';
import SalesSummary from 'screens/sales-summary/SalesSummary';
import Customer from 'screens/customer';
import Customers from 'screens/customers';
import Order from 'screens/order';
import SignIn from 'screens/sign-in/SignIn';
import Refund from 'screens/refund';
import Unauthorized from 'screens/unauthorized';
import { Header } from 'components/header';
import { Footer } from 'components/footer';
import { InstamenuAPIProvider } from 'api/instamenu';

import 'theme/index.scss';

export default function App() {
  return (
    <Router history={history}>
      <AuthProvider>
        <InstamenuAPIProvider>
          <UserProvider>
            <Header />
            <Switch>
              <PrivateRoute path="/orders/:id/refund" component={Refund} />
              <PrivateRoute path="/orders/:id" component={Order} />
              <PrivateRoute path="/customers/:id" component={Customer} />
              <PrivateRoute path="/customers" component={Customers} />
              <PrivateRoute path="/sales-summary" component={SalesSummary} />
              <Route path="/sign-in" component={SignIn} />
              <Route path="/unauthorized" component={Unauthorized} />
              <PrivateRoute path="/">
                <Redirect to="/sales-summary" />
              </PrivateRoute>
            </Switch>
            <Footer />
          </UserProvider>
        </InstamenuAPIProvider>
      </AuthProvider>
    </Router>
  );
}
