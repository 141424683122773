import { useMemo } from 'react';
import { ChartSection, ChartHeader } from 'components/core/charts';
import { topAddonsQuery } from './queries';
import { ViewMoreTable } from 'components/core/tables';
import { formatCurrency } from 'utils/format';
import { useSalesSummaryContext } from '../../SalesSummaryContext';
import { formatTimespan, formatTimeRange } from 'utils/format';
import { useMediaQuery } from 'react-responsive';
import breakpoints from 'theme/breakpoints.module.scss';

export default function TopAddons() {
  const isDesktop = useMediaQuery({ query: `(min-width: ${breakpoints.mobile})` });
  const { useStatsQuery, timespan, times } = useSalesSummaryContext();
  const [{ data, error, fetching }] = useStatsQuery({ query: topAddonsQuery });
  const columns = useMemo(
    () => [
      {
        Header: isDesktop ? 'Quantity Sold' : 'Qty',
        accessor: 'quantity',
        width: '15%',
      },
      { Header: 'Item Name', accessor: 'name', width: '50%' },
      {
        Header: 'Sales',
        accessor: 'amount',
        className: 'numeric',
        width: '25%',
        Cell: ({ value }) => formatCurrency(value),
      },
    ],
    [isDesktop]
  );
  return (
    <ChartSection
      empty={!(data?.stats.topAddons.length > 0)}
      loading={fetching}
      error={error}
      Header={
        <ChartHeader
          title="Top Addons"
          subtitle={[formatTimespan(timespan), formatTimeRange(times)].filter((x) => x).join('; ')}
          CSVData={
            data ? { data: data?.stats.topAddons, columns, filename: 'top-addons.csv' } : data
          }
        />
      }
    >
      <ViewMoreTable
        data={data?.stats.topAddons}
        columns={columns}
        initialState={{ sortBy: [{ id: 'quantity', desc: true }] }}
      />
    </ChartSection>
  );
}
