import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'urql';
import { formatCurrency } from 'utils/format';
import { Button } from 'components/core/buttons';
import { createRefundMutation, orderQuery } from './queries';
import { Tab, HorizontalTabs } from 'components/core/tabs';
import { useHistory } from 'react-router-dom';
import { Checkbox } from 'components/core/forms';
import { sum } from 'lodash';
import styles from './Refund.module.scss';
import { GraphQLErrorMessage } from 'components/core/errors';
import { CurrencyInput, FormField } from 'components/core/forms';
import { Link } from 'components/core/links';

export function formatOrderItemMods(orderItem) {
  return [...orderItem.modifiers]
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(({ name }) => name)
    .join(', ');
}

export default function Refund({ match }) {
  const history = useHistory();
  const id = match.params.id;
  const variables = useMemo(() => ({ id }), [id]);
  const [{ data, error }] = useQuery({ query: orderQuery, variables });

  const [items, setItems] = useState([]);
  const [tip, setTip] = useState();
  const [tab, setTab] = useState('Items');
  const [amountInput, setAmountInput] = useState();

  useEffect(() => {
    setItems(
      data?.order.orderItems.flatMap((orderItem) =>
        Array(orderItem.quantity).fill({ orderItem, checked: false })
      ) ?? []
    );
  }, [data]);

  const [{ error: mutationError, fetching: mutationFetching }, createRefund] =
    useMutation(createRefundMutation);

  const toggleItem = (index) => (checked) =>
    setItems(items.map((item, i) => (index === i ? { ...item, checked } : item)));
  const toggleAllItems = (checked) => {
    setItems(items.map((item) => ({ ...item, checked })));
    setTip(checked);
  };
  const toggleTip = (checked) => setTip(checked);

  if (error || (data && !data.order)) {
    return (
      <>
        <main style={{ padding: '5vh 0' }}>
          <div className="container">
            <h1>🤕 Oh-oh</h1>
            <p>We couldn’t find an order with id {id}</p>
          </div>
        </main>
      </>
    );
  }

  const order = data?.order;
  if (order) {
    const amount = (() => {
      if (tab === 'Items') {
        const itemTotal = sum(
          items.filter((item) => item.checked).map((item) => item.orderItem.refundableItemTotal)
        );
        return Math.min(itemTotal + (tip ? order.tip : 0), order.total - (tip ? 0 : order.tip));
      } else {
        return amountInput ?? 0;
      }
    })();

    const handleSubmit = async () => {
      if (amount > order.total) {
        // showToast({
        //   type: 'bottom',
        //   position: 'bottom',
        //   props: { Component: InvalidAmountToast },
        // });
        return;
      }
      const variables =
        tab === 'Items'
          ? {
              orderId: order.id,
              orderItemIds: items.filter((item) => item.checked).map((item) => item.orderItem.id),
              refundTip: !!tip,
            }
          : { orderId: order.id, total: amount };
      const response = await createRefund(variables);
      if (!response.error) {
        history.push(`/orders/${order.id}`);
      }
    };
    if (order.paymentType === 'CASH')
      return (
        <main>
          <div className="container">
            <h1>👋 We can help!</h1>
            <p>
              Cash orders cannot be refunded from the dashboard. To refund this order, please
              contact <a href="mailto:support@instamenu.cc">support@instamenu.cc</a> for assistance.
            </p>
            <p>
              <Link to={`/orders/${order.id}`}>Order #{order.number}</Link>
            </p>
          </div>
        </main>
      );
    if (order.refundableStatus === 'EXPIRED')
      return (
        <main>
          <div className="container">
            <h1>👋 We can help!</h1>
            <p>
              Refunds from a previous date cannot be issued from the dashboard. To refund this
              order, please contact <a href="mailto:support@instamenu.cc">support@instamenu.cc</a>{' '}
              for assistance.
            </p>
            <p>
              <Link to={`/orders/${order.id}`}>Order #{order.number}</Link>
            </p>
          </div>
        </main>
      );
    if (order.refundableStatus === 'REFUNDED')
      return (
        <main>
          <div className="container">
            <h1>👋 We can help!</h1>
            <p>
              A refund for this order has already been issued. To issue additional refunds, please
              contact <a href="mailto:support@instamenu.cc">support@instamenu.cc</a> for assistance.
            </p>
            <p>
              <Link to={`/orders/${order.id}`}>Order #{order.number}</Link>
            </p>
          </div>
        </main>
      );
    if (order.refundableStatus === 'UNPAID')
      return (
        <main>
          <div className="container">
            <h1>👋 Not so fast!</h1>
            <p>This order hasn’t been paid yet.</p>
            <p>
              <Link to={`/orders/${order.id}`}>Order #{order.number}</Link>
            </p>
          </div>
        </main>
      );
    return (
      <main>
        <div className="container">
          <h1>Issue Refund for Order #{order.number}</h1>
          <HorizontalTabs onChange={setTab} style={{ marginTop: '32px' }}>
            <Tab label="Items">
              <div style={{ marginTop: 24 }}>
                {(items.length > 1 || (items.length > 0 && order.tip > 0)) && (
                  <div className={styles.item}>
                    <Checkbox
                      checked={tip && items.every((item) => item.checked)}
                      onChange={toggleAllItems}
                    >
                      Select All
                    </Checkbox>
                  </div>
                )}
                {items.map(({ orderItem, checked }, index) => (
                  <div key={index}>
                    <Checkbox checked={checked} onChange={toggleItem(index)}>
                      <div>
                        <div className={styles.item}>{orderItem.item.name}</div>
                        {orderItem.modifiers.length > 0 && (
                          <div className={styles.modifier}>{formatOrderItemMods(orderItem)}</div>
                        )}
                      </div>
                    </Checkbox>
                  </div>
                ))}
                {order.tip > 0 && (
                  <div className={styles.item}>
                    <Checkbox checked={tip} onChange={toggleTip}>
                      <div style={styles.itemdiv}>Tip ({formatCurrency(order.tip)})</div>
                    </Checkbox>
                  </div>
                )}
              </div>
            </Tab>
            <Tab label="Amount">
              <div style={{ margin: '40px 0' }}>
                <FormField
                  label="Enter amount"
                  value={amountInput}
                  onChange={setAmountInput}
                  Component={CurrencyInput}
                  style={{ width: '312px' }}
                />
                <div className={styles.maxLabel}>
                  You can refund up to {formatCurrency(order.total)}
                </div>
              </div>
            </Tab>
          </HorizontalTabs>
          <div style={{ margin: '40px 0' }}>
            <Button
              style={{ minWidth: '200px' }}
              disabled={amount === 0}
              onClick={handleSubmit}
              loading={mutationFetching}
            >
              {'Refund' + (amount ? ' ' + formatCurrency(amount) : '')}
            </Button>
            <GraphQLErrorMessage error={mutationError} />
          </div>
        </div>
      </main>
    );
  }
  return null;
}

// const styles = {
//   item: { marginVertical: 16 },
//   itemModdiv: { fontFamily: fonts.book, fontSize: 14, color: colors.gray70, marginTop: 4 },
//   itemdiv: { fontFamily: fonts.medium, fontSize: 16, marginTop: 2 },
//   maxdiv: { fontFamily: fonts.book, fontSize: 14, marginTop: 12, color: colors.gray70 },
// };
