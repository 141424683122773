import { Fragment } from 'react';
import { formatCurrency } from 'utils/format';
import styles from './OrderTable.module.scss';

export default function Order({ order }) {
  return (
    <table className={styles.table}>
      <tbody>
        {order.orderItems.map((orderItem, index) => (
          <Fragment key={index}>
            <tr className={styles.itemRow}>
              <td>
                {orderItem.quantity} × {orderItem.item.name}
                {orderItem.itemRefund && (
                  <span className={styles.debit}> ({orderItem.itemRefund.quantity} Refunded)</span>
                )}
              </td>
              <td>{formatCurrency(orderItem.price + (orderItem.discountAmount ?? 0))}</td>
            </tr>
            {[...orderItem.modifiers]
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((modifier, index) => (
                <tr key={index} className={styles.modifierRow}>
                  <td>
                    {modifier.name}
                    {modifier.price > 0 && <span> ({formatCurrency(modifier.price)})</span>}
                  </td>
                </tr>
              ))}
            {(orderItem.instructions || orderItem.instructionsPrice) && (
              <tr className={styles.modifierRow}>
                <td>
                  {orderItem.instructions} ({formatCurrency(orderItem.instructionsPrice)})
                </td>
              </tr>
            )}
            {orderItem.discount && (
              <tr className={styles.discountRow}>
                <td className={styles.discountText}>{orderItem.discount.name}</td>
                <td className={styles.discountText}>-{formatCurrency(orderItem.discountAmount)}</td>
              </tr>
            )}
          </Fragment>
        ))}
        {order.discount && (
          <tr className={[styles.itemRow, styles.discountRow].join(' ')}>
            <td className={styles.discountText}>{order.discount.name}</td>
            <td className={styles.discountText}>-{formatCurrency(order.discountAmount)}</td>
          </tr>
        )}
      </tbody>
      <tfoot>
        <tr>
          <td>Subtotal</td>
          <td>{formatCurrency(order.grossSubtotal)}</td>
        </tr>
        {order.totalDiscountAmount > 0 && (
          <tr>
            <td className={styles.discountText}>Total Discounts</td>
            <td className={styles.discountText}>-{formatCurrency(order.totalDiscountAmount)}</td>
          </tr>
        )}
        {order.paymentStatus === 'PAID' && (
          <tr>
            <td>Tip</td>
            <td>{formatCurrency(order.tip)}</td>
          </tr>
        )}
        <tr>
          <td>Taxes & Fees</td>
          <td>{formatCurrency(order.tax + order.processingFee)}</td>
        </tr>
        <tr>
          <td style={styles.summaryColumn} className={[styles.summaryText, { fontFamily: 'bold' }]}>
            Total
          </td>
          <td>{formatCurrency(order.total)}</td>
        </tr>
        {order.refund && (
          <tr className={styles.debit}>
            <td>Refunded</td>
            <td>{formatCurrency(order.refund.total)}</td>
          </tr>
        )}
      </tfoot>
    </table>
  );
}
