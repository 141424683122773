import { ChartSection, ChartHeader } from 'components/core/charts';
import { overviewQuery } from './queries';
import { formatCurrency } from 'utils/format';
import styles from './Overview.module.scss';
import typography from 'theme/typography.module.scss';
import { useSalesSummaryContext } from '../../SalesSummaryContext';
import { formatTimespan, formatTimeRange } from 'utils/format';

export default function Overview() {
  const { useStatsQuery, timespan, times } = useSalesSummaryContext();
  const [{ data, error, fetching }] = useStatsQuery({ query: overviewQuery });
  const CSVData = () => {
    const { stats } = data;
    return {
      data: [
        { label: 'Total Collected', value: formatCurrency(stats.total) },
        { label: 'Net Sales', value: formatCurrency(stats.subtotal) },
        { label: 'Sales Tax', value: formatCurrency(stats.tax) },
        { label: 'Total Refunds', value: formatCurrency(stats.totalRefunds) },
        {
          label: 'Discounts',
          value: formatCurrency(stats.totalDiscounts),
        },
        { label: 'Transactions', value: stats.numOrders },
        { label: 'Average Sale', value: formatCurrency(stats.averageSale) },
        { label: 'Total Tips', value: formatCurrency(stats.tips) },
        { label: 'Average Tip Amount', value: formatCurrency(stats.averageTip) },
        {
          label: 'Average Tip Percent',
          value: stats.averageTipPercent,
        },
      ],
      columns: {
        label: 'Label',
        value: 'Value',
      },
      filename: `overview.csv`,
    };
  };
  return (
    <ChartSection
      error={error}
      loading={fetching}
      empty={!data?.stats}
      Header={
        <ChartHeader
          title="Overview"
          subtitle={[formatTimespan(timespan), formatTimeRange(times)].filter((x) => x).join('; ')}
          CSVData={CSVData}
        />
      }
    >
      <ChartData data={data?.stats} />
    </ChartSection>
  );
}

function Tile({ children, label, value }) {
  return (
    <div className={styles.tile}>
      <div className={styles.label}>{label}</div>
      <div className={styles.value}>{value ?? children}</div>
    </div>
  );
}

function ChartData({ data }) {
  return (
    <div className={styles.tiles}>
      <Tile label="Total Collected" value={formatCurrency(data.total)} />
      <Tile label="Net Sales" value={formatCurrency(data.subtotal)} />
      <Tile label="Sales Tax" value={formatCurrency(data.tax)} />
      <Tile label="Total Refunds" value={formatCurrency(data.totalRefunds)} />
      <Tile label="Discounts" value={formatCurrency(data.totalDiscounts)} />
      <Tile label="Transactions" value={data.numOrders} />
      <Tile label="Average Sale" value={formatCurrency(data.averageSale)} />
      <Tile label="Total Tips" value={formatCurrency(data.tips)} />
      <Tile label="Average Tip">
        {formatCurrency(data.averageTip)} <span className={typography.numericParenthesis}>(</span>
        {data.averageTipPercent}%<span className={typography.numericParenthesis}>)</span>
      </Tile>
      {data.restaurantBonus ? (
        <Tile label="Restaurant Bonus" value={formatCurrency(data.restaurantBonus)} />
      ) : null}
      {data.employeeBonus ? (
        <Tile label="Employee Bonus" value={formatCurrency(data.employeeBonus)} />
      ) : null}
    </div>
  );
}
