import { gql } from '@urql/core';

export const topItemsQuery = gql`
  query CustomerTopItemsQuery($userId: ID!) {
    customer(userId: $userId) {
      topItems {
        amount
        name
        quantity
      }
    }
  }
`;
