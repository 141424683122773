import { useAuthContext } from 'contexts/firebase';
import { useUserContext } from 'contexts/user';
import { Redirect, Route } from 'react-router-dom';

export default function PrivateRoute({ children, component: Component, ...rest }) {
  const { isAuthenticated } = useAuthContext();
  const { isAuthorized } = useUserContext();
  const render = (props) => {
    if (!isAuthenticated) {
      return <Redirect to={{ pathname: '/sign-in', state: { from: props.location } }} />;
    }
    if (!isAuthorized) {
      return <Redirect to="/unauthorized" />;
    }

    return Component ? <Component {...props} /> : children;
  };
  return <Route {...rest} render={render} />;
}
