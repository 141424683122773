import React from 'react';
import { history } from 'routes';
import { useAuthContext } from 'contexts/firebase';
import { Button } from 'components/core/buttons';
import { Redirect } from 'react-router-dom';
import { useUserContext } from 'contexts/user';

export default function Unauthorized() {
  const { isAuthenticated, signOut } = useAuthContext();
  const { isAuthorized } = useUserContext();
  const handleSignOut = () => {
    signOut();
    history.replace('/sign-in', { from: history.location.state?.from });
  };

  if (!isAuthenticated) {
    return <Redirect to="/sign-in" />;
  }

  if (isAuthorized) {
    return <Redirect to="/" />;
  }

  return (
    <main className="container">
      <div style={{ padding: '40px 0' }}>
        <p>
          This account does not have dashboard access. Please contact{' '}
          <a href="mailto:support@instamenu.cc">support@instamenu.cc</a>.
        </p>
        <p>API endpoint: {process.env.REACT_APP_API_ENDPOINT}</p>
        <Button onClick={handleSignOut}>Sign out</Button>
      </div>
    </main>
  );
}
