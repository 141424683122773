import styles from './Tabs.module.scss';
import { useState } from 'react';

export default function HorizontalTabs({ children, onChange = () => {}, style }) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleTabPress = ({ child, index }) => {
    onChange(child.props.label);
    setSelectedIndex(index);
  };
  const tabs = Array.isArray(children) ? children : [children];
  return (
    <div className={styles.tabs} style={style}>
      <div className={styles.tabBar}>
        {tabs.map((child, index) => (
          <div
            key={index}
            className={[styles.tab, selectedIndex === index ? styles.selected : false]
              .filter((x) => x)
              .join(' ')}
            onClick={() => handleTabPress({ index, child })}
          >
            <div className={styles.tabLabel}>{child.props.label}</div>
          </div>
        ))}
      </div>
      {tabs[selectedIndex] ?? null}
    </div>
  );
}
