import { useMemo, useState } from 'react';
import { ChartSection, ChartHeader } from 'components/core/charts';
import { dailySalesQuery } from './queries';
import { VictoryAxis, VictoryBar, VictoryGroup, VictoryStack } from 'victory';
import { DateTime } from 'luxon';
import ResponsiveVictoryChart from './ResponsiveVictoryChart';
import colors from 'theme/colors.module.scss';
import Tooltip from './Tooltip';
import { formatDate, dateFormats, formatCurrency, formatHour, formatTimespan } from 'utils/format';
import { useSalesSummaryContext } from '../../SalesSummaryContext';
import styles from './DailySales.module.scss';
import ChartError from 'components/core/charts/ChartError';

export default function DailySales() {
  const { useStatsQuery, restaurants, timespan, times } = useSalesSummaryContext();
  const [{ data, error, fetching }] = useStatsQuery({ query: dailySalesQuery });
  const comparisonVariables = useMemo(
    () => ({
      restaurantIds: restaurants?.map(({ id }) => id),
      startDate: timespan?.comparisonStart?.toISODate(),
      endDate: timespan?.comparisonEnd?.toISODate(),
      ...times,
    }),
    [restaurants, timespan, times]
  );
  const [{ data: comparisonData, error: comparisonError, fetching: comparisonLoading }] =
    useStatsQuery({
      query: dailySalesQuery,
      variables: comparisonVariables,
      pause: !timespan?.comparisonStart,
    });
  return (
    <ChartSection
      Header={
        <ChartHeader
          title="Daily Sales"
          subtitle={
            formatTimespan(timespan) +
            (timespan?.comparisonLabel ? ' compared to ' + timespan.comparisonLabel : '')
          }
        />
      }
      error={error || comparisonError}
      loading={fetching || comparisonLoading}
      empty={!(data?.stats.orders.length > 0)}
    >
      <ChartData data={data?.stats} comparisonData={comparisonData?.stats} timespan={timespan} />
    </ChartSection>
  );
}

function ChartData({ data, comparisonData, timespan }) {
  try {
    const [tooltip, setTooltip] = useState();

    // attach an hour label to each order
    const groups = [comparisonData, data]
      .filter((x) => x)
      .map((group) => ({
        ...group,
        orders: group.orders.map((order) => ({
          ...order,
          hour: DateTime.fromISO(order.paidAt).setZone(order.restaurant.timeZone).get('hour'),
        })),
      }));

    // get the x-axis range
    const hours = groups
      .flatMap((group) => group.orders.map((order) => order.hour))
      .filter((x) => Number.isInteger(x));

    const min = Math.min(...hours);
    const max = Math.max(...hours);

    // bucket the orders by hour
    const bucketedGroups = groups.map(({ orders }) => {
      const buckets = Array(max - min + 1)
        .fill(null)
        .map((_, index) => ({
          hour: min + index,
          tips: 0,
          subtotal: 0,
          xlabel: formatHour(min + index),
          time: formatHour(min + index) + ' to ' + formatHour(min + index + 1),
        }));
      orders.forEach((order) => {
        buckets[order.hour - min].tips += order.tip;
        buckets[order.hour - min].subtotal += order.subtotal;
      });
      return buckets;
    });

    const handleMouseOver = (props) => setTooltip(props);
    const handleMouseOut = () => setTooltip();
    const total = data.orders.reduce((sum, order) => sum + order.total, 0);
    const comparisonTotal = comparisonData
      ? comparisonData.orders.reduce((sum, order) => sum + order.total, 0)
      : 0;
    const delta = comparisonTotal ? 100 * (total / comparisonTotal - 1) : undefined;

    const labelForTimespan = (start, end) =>
      formatDate(start, dateFormats.dateMedium) +
      (start.hasSame(end, timespan.resolution)
        ? ''
        : ' to ' + formatDate(end, dateFormats.dateMedium));

    return (
      <div>
        <Tooltip tooltip={tooltip} />
        {comparisonData && (
          <div className={styles.legend}>
            <div className={styles.legendItem}>
              <div className={styles.swatch} style={{ background: '#797979' }} />
              <div className={styles.legendLabel}>
                {labelForTimespan(timespan.comparisonStart, timespan.comparisonEnd)}
                <span> ({formatCurrency(comparisonTotal)})</span>
              </div>
            </div>
            <div className={styles.legendItem}>
              <div className={styles.swatch} style={{ background: '#34AC68' }} />
              <div className={styles.legendLabel}>
                {labelForTimespan(timespan.start, timespan.end)}
                <span> ({formatCurrency(total)}) </span>
                {delta && (
                  <span style={{ color: delta < 0 ? 'red' : '#34AC68' }}>
                    {delta < 0 ? '' : '+'}
                    {Math.round(delta)}%
                  </span>
                )}
              </div>
            </div>
          </div>
        )}
        <ResponsiveVictoryChart
          domainPadding={{ x: 25 }}
          padding={{ top: 0, bottom: 50, left: 50, right: 0 }}
          minWidth={bucketedGroups[0].length * 50}
        >
          <VictoryAxis
            style={{
              axis: { stroke: colors.border },
              tickLabels: { fontSize: 12, padding: 10, fontFamily: 'Gotham' },
            }}
          />
          <VictoryAxis
            dependentAxis
            style={{
              ticks: { stroke: colors.border, size: 5 },
              grid: { stroke: colors.border },
              axis: { stroke: colors.border },
              tickLabels: {
                fontSize: 12,
                fontFamily: 'Gotham',
                fontVariantNumeric: 'tabular-nums',
              },
            }}
            tickFormat={(t) => formatCurrency(t, { cents: false })}
          />
          <VictoryGroup offset={15} style={{ data: { width: 15 } }}>
            {bucketedGroups.map((bucket, index) => (
              <VictoryStack
                key={index}
                colorScale={
                  bucketedGroups.length > 1 && index === 0
                    ? ['#797979', '#c5c5c5']
                    : ['#34ac68', '#93DBB2']
                }
                style={{ data: { cursor: 'pointer' } }}
                events={[
                  {
                    childName: 'all',
                    target: 'data',
                    eventHandlers: {
                      onMouseOut: () => handleMouseOut(),
                      onMouseOver: (event, data, _, victoryEvent) => {
                        handleMouseOver({
                          index: data.index,
                          data: bucketedGroups,
                          labels: [
                            labelForTimespan(timespan.start, timespan.end),
                            comparisonData &&
                              labelForTimespan(timespan.comparisonStart, timespan.comparisonEnd),
                          ].filter((x) => x),
                          boundingBox: toAbsoluteCoordinates(event.target.getBoundingClientRect()),
                        });
                      },
                    },
                  },
                ]}
              >
                <VictoryBar name="total" data={bucket} x="xlabel" y="subtotal" />
                <VictoryBar name="tips" data={bucket} x="xlabel" y="tips" />
              </VictoryStack>
            ))}
          </VictoryGroup>
        </ResponsiveVictoryChart>
      </div>
    );
  } catch (error) {
    return <ChartError />;
  }
}

const toAbsoluteCoordinates = (rect) => ({
  x: rect.x + window.scrollX,
  y: rect.y + window.scrollY,
  width: rect.width,
  height: rect.height,
});
