import { useMemo } from 'react';
import { ChartSection, ChartHeader } from 'components/core/charts';
import { useQuery } from 'urql';
import { customersQuery } from './queries';
import { ViewMoreTable } from 'components/core/tables';
import { formatCurrency, formatPhone } from 'utils/format';
import { useCustomersContext } from './Context';
import { Link } from 'components/core/links';

export default function CustomersTable() {
  const { customersQueryVariables } = useCustomersContext();
  const [{ data, error, fetching }] = useQuery({
    query: customersQuery,
    variables: customersQueryVariables,
  });
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'user.name',
        width: '10%',
        Cell: ({ value, row }) => <Link to={`/customers/${row.original.user.id}`}>{value}</Link>,
        CSVCell: ({ value }) => value,
      },
      {
        Header: 'Email',
        accessor: 'user.email',
        width: '10%',
        Cell: ({ value }) => <a href={`mailto:${value}`}>{value}</a>,
        CSVCell: ({ value }) => value,
      },
      {
        Header: 'Phone',
        accessor: 'user.phone',
        width: '10%',
        Cell: ({ value }) => <a href={`tel:${value}`}>{formatPhone(value)}</a>,
        CSVCell: ({ value }) => value,
      },
      {
        Header: 'Total Spent',
        accessor: 'spentAmount',
        className: 'numeric',
        width: '10%',
        Cell: ({ value }) => formatCurrency(value),
      },
      {
        Header: 'Orders',
        accessor: 'numOrders',
        className: 'numeric',
        width: '10%',
        Cell: ({ value }) => value,
      },
      {
        Header: 'Marketing Emails',
        accessor: 'canMarket',
        width: '10%',
        Cell: ({ value }) => (value ? 'Opted in' : ''),
      },
    ],
    []
  );
  return (
    <ChartSection
      error={error}
      loading={fetching}
      empty={!(data?.customers.length > 0)}
      Header={
        <ChartHeader
          title="Customers"
          // subtitle={[formatTimespan(timespan), formatTimeRange(times)].filter((x) => x).join('; ')}
          CSVData={data ? { data: data?.customers, columns, filename: 'customers.csv' } : data}
        />
      }
    >
      <ViewMoreTable
        data={data?.customers}
        columns={columns}
        // initialState={{ sortBy: [{ id: 'paidAt', desc: true }] }}
      />
    </ChartSection>
  );
}
