import styles from './ChartHeader.module.scss';
import CSVExportButton, { tableCSVData } from './CSVExportButton';

export default function ChartHeader({ title, subtitle, CSVData }) {
  const CSVDataFn = typeof CSVData === 'function' ? CSVData : () => tableCSVData(CSVData);

  return (
    <div className={styles.chartHeader}>
      <div>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subtitle}</div>
      </div>
      <div className={styles.actions}>{CSVData && <CSVExportButton getData={CSVDataFn} />}</div>
    </div>
  );
}
