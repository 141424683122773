import styles from './Checkbox.module.scss';

export default function Checkbox({ checked, children, onChange }) {
  const stroke = checked ? '#34AC68' : '#C5C4C4';
  return (
    <label className={styles.container}>
      <input
        className={styles.container}
        type="checkbox"
        checked={checked}
        onChange={() => onChange(!checked)}
      />
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="1" y="1" width="24" height="24" stroke={stroke} strokeWidth="1" rx="2" ry="2" />
        {checked && (
          <path
            d="M20.9345 9.64036L11.0142 18.9386C10.9268 19.0205 10.7853 19.0205 10.6981 18.9386L5.06538 13.6588C4.97821 13.5773 4.97821 13.4447 5.06538 13.3628L6.43406 12.08C6.52144 11.9983 6.66293 11.9983 6.7501 12.08L10.8564 15.9285L19.2499 8.06128C19.3375 7.97957 19.4786 7.97957 19.566 8.06128L20.9345 9.34414C21.0218 9.42585 21.0218 9.55827 20.9345 9.64036Z"
            fill={stroke}
          />
        )}
      </svg>
      <div className={styles.label}>{children}</div>
    </label>
  );
}
